import { Controller } from "@hotwired/stimulus"
import jsQR from "jsqr";

// https://github.com/cozmo/jsQR

// Connects to data-controller="qr-scanner"
export default class extends Controller {
  static targets = [ "qrCode", "submitButton" ];

  connect() {
    this.video = document.createElement("video")
    this.canvasElement = document.getElementById("canvas");
    this.canvas = this.canvasElement.getContext("2d");
    this.loadingMessage = document.getElementById("loadingMessage");
    this.outputContainer = document.getElementById("output");
    this.outputMessage = document.getElementById("outputMessage");
    this.outputData = document.getElementById("outputData");
    this.formSubmittedAt = Date.now();

    // Use facingMode: environment to attemt to get the front camera on phones
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((stream) => {
      this.video.srcObject = stream;
      this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
      this.video.play();
      requestAnimationFrame(this.tick);
    });

  }


  // sleep(milliseconds) {
  //   const date = Date.now();
  //   let currentDate = null;
  //   do {
  //     currentDate = Date.now();
  //   } while (currentDate - date < milliseconds);
  // }

  submitTheCode() {
    this.qrCodeTarget.value = this.code.data;
    var now = Date.now();
    console.log(now - this.formSubmittedAt);
    if (now - this.formSubmittedAt > 2000) {
      this.submitButtonTarget.removeAttribute("disabled")
      this.submitButtonTarget.click();
      this.formSubmittedAt = Date.now();
      console.log("click");
    }
  }


  drawLine(begin, end, color) {
    this.canvas.beginPath();
    this.canvas.moveTo(begin.x, begin.y);
    this.canvas.lineTo(end.x, end.y);
    this.canvas.lineWidth = 4;
    this.canvas.strokeStyle = color;
    this.canvas.stroke();
  }


  tick = () => {
    this.loadingMessage.innerText = "⌛ Loading video..."
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.loadingMessage.hidden = true;
      this.canvasElement.hidden = false;
      this.outputContainer.hidden = false;

      this.canvasElement.height = this.video.videoHeight;
      this.canvasElement.width = this.video.videoWidth;
      this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
      this.imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
      this.code = jsQR(this.imageData.data, this.imageData.width, this.imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (this.code) {
        // a code was found!

        // draw a rectangle around the qr code
        this.drawLine(this.code.location.topLeftCorner, this.code.location.topRightCorner, "#FF3B58");
        this.drawLine(this.code.location.topRightCorner, this.code.location.bottomRightCorner, "#FF3B58");
        this.drawLine(this.code.location.bottomRightCorner, this.code.location.bottomLeftCorner, "#FF3B58");
        this.drawLine(this.code.location.bottomLeftCorner, this.code.location.topLeftCorner, "#FF3B58");

        this.outputMessage.hidden = true;
        this.outputData.parentElement.hidden = false;
        this.outputData.innerText = this.code.data;

        // submit the form
        this.submitTheCode()
        // this.qrCodeTarget.value = this.code.data;
        // // this.formTarget.submit();
        // this.submitButtonTarget.click();
        // this.sleep(1000);
      } else {
        this.outputMessage.hidden = false;
        this.outputData.parentElement.hidden = true;
      }
    }
    requestAnimationFrame(this.tick);
  }
}



// var video = document.createElement("video");
// var canvasElement = document.getElementById("canvas");
// var canvas = canvasElement.getContext("2d");
// var loadingMessage = document.getElementById("loadingMessage");
// var outputContainer = document.getElementById("output");
// var outputMessage = document.getElementById("outputMessage");
// var outputData = document.getElementById("outputData");

// function drawLine(begin, end, color) {
//   canvas.beginPath();
//   canvas.moveTo(begin.x, begin.y);
//   canvas.lineTo(end.x, end.y);
//   canvas.lineWidth = 4;
//   canvas.strokeStyle = color;
//   canvas.stroke();
// }

// // Use facingMode: environment to attemt to get the front camera on phones
// navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
//   video.srcObject = stream;
//   video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
//   video.play();
//   requestAnimationFrame(tick);
// });

// function tick() {
//   loadingMessage.innerText = "⌛ Loading video..."
//   if (video.readyState === video.HAVE_ENOUGH_DATA) {
//     loadingMessage.hidden = true;
//     canvasElement.hidden = false;
//     outputContainer.hidden = false;

//     canvasElement.height = video.videoHeight;
//     canvasElement.width = video.videoWidth;
//     canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
//     var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
//     var code = jsQR(imageData.data, imageData.width, imageData.height, {
//       inversionAttempts: "dontInvert",
//     });
//     if (code) {
//       drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
//       drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
//       drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
//       drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
//       outputMessage.hidden = true;
//       outputData.parentElement.hidden = false;
//       outputData.innerText = code.data;
//     } else {
//       outputMessage.hidden = false;
//       outputData.parentElement.hidden = true;
//     }
//   }
//   requestAnimationFrame(tick);
// }