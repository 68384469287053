import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-visibility"
export default class extends Controller {
  static targets = [ "trueBlock", "falseBlock" ];

  connect() {
    console.log('toggle-visibility connected');
  }

  toggle(bool) {
    console.log('C');
    if (bool) {
      if (this.hasTrueBlockTarget) {
        this.trueBlockTarget.classList.remove('hidden');
      }
      if (this.hasFalseBlockTarget) {
        this.falseBlockTarget.classList.add('hidden');
      }
    } else {
      if (this.hasTrueBlockTarget) {
        this.trueBlockTarget.classList.add('hidden');
      }
      if (this.hasFalseBlockTarget) {
        this.falseBlockTarget.classList.remove('hidden');
      }
    }
  }
}
