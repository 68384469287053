import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="myclipboard"
export default class extends Controller {
  static targets = [ "source", "msg" ];

  connect() {
    // console.log("Hello, Stimulus!");
  }

  copyText() {
    const href = this.sourceTarget.getAttribute('href');
    // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
    navigator.clipboard.writeText(href).then(
      () => {
        /* success */
        this.msgTarget.classList.remove('invisible');
        setTimeout(this.show_msg, 1000);
      },
      (err) => {
        console.log('Something went wrong', err)
        /* failure */
      },
    );
  }

  show_msg = () => {
    this.msgTarget.classList.add('invisible');
  }


}

