import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clock"
export default class extends Controller {
  static targets = [ "wrapper" ];

  connect() {
    setInterval(() => {
      this.renderClock();
    }, 1000);
  }

  renderClock() {
    this.wrapperTarget.innerHTML = new Date().toLocaleTimeString();
  }

}
