import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="guest-form"

// https://stimulus.hotwire.dev/reference/controllers
// 
export default class extends Controller {
  static targets = [ "form" ];

  connect() {
    // console.log("Hello, Stimulus!");
  }

  // handle a button to clear the form
  clearForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.formTarget.reset();
  }
}

