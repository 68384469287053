import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pict-picker"

// https://stimulus.hotwire.dev/reference/controllers
// 
// export default class extends Controller {
class PictPicker extends Controller {
  static targets = [ "hiddenField", "pict" ];
  static values = { num: String }; // 'one' is for single selection (radio button), otherwise is a multiple selection (checkbox)
  static selectedClasses = ["border-8", "grayscale-0"];
  static unselectedClasses = ["border-0", "grayscale"];

  connect() {
    this.decorate();
  }


  select(event) {
    let ids;
    if (this.numValue == 'one') {
      this.hiddenFieldTarget.value = event.currentTarget.dataset.id;
    } else {
      ids = this.hiddenFieldTarget.value.split(",")
      if (ids.includes(event.currentTarget.dataset.id)) {
        ids = ids.filter((id) => id != event.currentTarget.dataset.id);
      } else {
        ids.push(event.currentTarget.dataset.id);
      }
      this.hiddenFieldTarget.value = ids.filter((id) => id != "").join(",");
    }
    this.decorate();
  }

  
  decorate() {
    let ids = this.hiddenFieldTarget.value.split(",");
    this.pictTargets.forEach((pict) => {
      if (ids.includes(pict.dataset.id)) {
        pict.classList.remove(...this.constructor.unselectedClasses);
        pict.classList.add(...this.constructor.selectedClasses);
      } else {
        pict.classList.remove(...this.constructor.selectedClasses);
        pict.classList.add(...this.constructor.unselectedClasses);
      }
    });
  }
}

export default PictPicker;