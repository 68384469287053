import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-categ"
export default class extends Controller {
  // static targets = [ "wrapper" ];

  show_hide_organizer_selection = () => {
    if ($('#video_category').find(':selected')[0].value == "related_to_the_organizers") {
      $('#organizer_selection').show();
    } else {
      $('#organizer_selection').hide();
    } 
  }

  connect() {
    this.show_hide_organizer_selection();
    $('#video_category').on('select2:select', (e) => {
      // e.params.data.id is the same as $('#video_category').find(':selected')[0].value
      this.show_hide_organizer_selection();
    });
  }
}