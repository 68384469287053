import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notice"
export default class extends Controller {
  static targets = [ "wrapper" ];

  connect() {
    setTimeout(() => {
      this.wrapperTarget.classList.remove("animate__fadeIn");
      this.wrapperTarget.classList.add("animate__fadeOut");
    }, 2000);
  }

}
