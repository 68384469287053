import { Controller } from "@hotwired/stimulus"
import {enter, leave, toggle} from 'el-transition' // https://www.npmjs.com/package/el-transition

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = [ "modal", "overlay", "content" ]
  static values = {
    closeAfter: Number
  }

  connect() {
    // console.log("Modal controller connected");
    if (this.closeAfterValue) {
      // console.log("Modal controller close after " + this.closeAfterValue + "ms");
      setTimeout(() => {
        this.close()
      }, this.closeAfterValue)
    }
  }

  none(event) {
    console.log("Modal controller none");
    event.stopPropagation();
  }

  open() {
    console.log("Modal controller open");
    enter(this.modalTarget)
    enter(this.overlayTarget)
    enter(this.contentTarget)
  }

  // copy & paste the slug into the slug field
  close() {
    leave(this.modalTarget)
    leave(this.overlayTarget)
    leave(this.contentTarget)
  }
}

