import { Controller } from "@hotwired/stimulus"
var slug = require('slug')
import debounce from 'debounce';

// Connects to data-controller="cal-slug"
export default class extends Controller {
  static values = { useit: String }

  connect() {
    this.slugIsEmpty = document.getElementById('activity_calendar_slug').value=="" // the record is a new one and the slug field is empty
    this.button = `<button class='ml-5 hover:underline text-blue-500 hover:text-blue-600' data-action='click->cal-slug#useIt'>${this.useitValue}</button>`
    this._slugify()
    // this.slugify = debounce(this.slugify.bind(this), 300) // alternative
  }

  slugify() {
    this.slugify = debounce(this._slugify, 300);    
  }

  _slugify = () => {
    const n = document.getElementById('activity_calendar_name').value
    const s = slug(n, { lower: true })
    if (this.slugIsEmpty) {
      document.getElementById('activity_calendar_slug').value = s
    } else {
      document.getElementById('slug-help').innerHTML = `<span class="text-gray-400 mr-2">Slug:</span> <b id="slug-preview">${s}</b> ${this.button}` // or .textContent = ...
    }
  }

  // copy & paste the slug into the slug field
  useIt(event) {
    event.preventDefault()
    const s = document.getElementById('slug-preview').textContent
    document.getElementById('activity_calendar_slug').value = s
  }
}

