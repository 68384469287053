import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multimedia-list-loader"
// export default class extends Controller {
export default class extends Controller {
  static values = {
    team: String,
    organizer: String
  }
  static targets = [ "mmList" ];

  connect() {
    // this.fetchCollections(this.organizerValue, false)
  }

  updateDependentFields(event) {
    const originalSelect2Event = event.detail.event
    // console.log(event.type);
    // console.log(event.detail.event);

    this.fetchCollections(originalSelect2Event.target.value, true)
  }

  fetchCollections(organizer_id, clean_fields) {
    const searchParams = new URLSearchParams({ 
      organizer_id: organizer_id
     });

    fetch(`/account/teams/${this.teamValue}/pictures/organizer_selection?`+searchParams)
      .then (response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        if (clean_fields) {
          $('#activity_activity_main_picture_id').val('');
          $('#activity_activity_main_video_id').val('');
        }
      });

    fetch(`/account/teams/${this.teamValue}/videos/organizer_selection?`+searchParams)
      .then (response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        if (clean_fields) {
          $('#activity_activity_selection_pictures_ids').val('');
          $('#activity_activity_selection_videos_ids').val('');
        }
      });
    }
}
