import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-checkbox"
export default class extends Controller {
  static targets = [ "hiddenField", "button", "slider" ];
  static buttonEnabledClass = "bg-blue-600"
  static buttonNotEnabledClass = "bg-gray-200"
  static outlets = [ "toggle-visibility" ];

  connect() {
    const status = this.hiddenFieldTarget.value;

    if (status=='true') {
      this.buttonTarget.classList.add(this.constructor.buttonEnabledClass);
      this.sliderTarget.classList.add('translate-x-5');
    } else { // false
      this.buttonTarget.classList.add(this.constructor.buttonNotEnabledClass);
      this.sliderTarget.classList.add('translate-x-0');
    }
    this.handleVisibility()
  }

  toggle(event) {
    const status = this.hiddenFieldTarget.value;

    this.buttonTarget.classList.toggle(this.constructor.buttonEnabledClass);
    this.buttonTarget.classList.toggle(this.constructor.buttonNotEnabledClass);
    this.sliderTarget.classList.toggle('translate-x-0');
    this.sliderTarget.classList.toggle('translate-x-5');

    this.hiddenFieldTarget.value = status=="true" ? "false" : "true"
    this.handleVisibility()
  }

  handleVisibility() {
    const status = this.hiddenFieldTarget.value;
    if (this.hasToggleVisibilityOutlet) {
      this.toggleVisibilityOutlets.forEach(element => {
        element.toggle(status=='true');
      })
    }
  }
}
